.ant-menu {
	// height: 100%;
	height: calc(100% - 103px);
}

.ant-menu-inline {
	background-color: rgba(0, 0, 0, 0.15) !important;
}

.sider-shadow {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.ant-layout-sider-zero-width-trigger {
	display: none;
}

.ant-layout-sider {
	background: linear-gradient(
		180deg,
		rgba(2, 0, 36, 1) 0%,
		rgb(32, 64, 124) 35%,
		rgb(76, 107, 165) 100%
	);
}

.sider-logo {
	padding: 10px;
	border-bottom: 1px solid #4b545c;
}

.ant-menu-light .sider-logo {
	border: none;
	border-bottom: 1px solid #dee2e6;
}

.menu-cover {
	background-color: rgba(0, 0, 0, 0.45);
	// #8282826b;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 998;
	transition: 0.3s all;
}

.menu-over {
	position: absolute;
	height: 100%;
	z-index: 999;
}

.version {
	position: absolute;
	bottom: 5px;
	width: 100%;
	text-align: center;
	color: #203c6f;
}

@media screen and (min-width: 768px) {
	.menu-over {
		position: relative;
		height: unset;
	}
}
