@import './variables';

#root {
	min-width: 100%;
	min-height: 100%;
	height: 100vh;
}

.App {
	text-align: center;
}

.layout {
	position: relative;
	min-height: 100%;
}

.content {
	// min-width: 400px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	&-header {
		border-bottom: 1px solid #dee2e6;
	}
}

.in-table-form .ant-form-item-control .ant-form-item-explain-error {
	position: absolute;
	top: 100%;
}

.sider-avatar {
	// overflow: hidden;
	// white-space: nowrap;
	padding-bottom: 1rem;
	margin: 1rem;
	border-bottom: 1px solid #4b545c;
	div {
		display: inline-block;
		padding: 5px 5px 5px 10px;
		box-sizing: border-box;
		a {
			display: block;
		}
	}
}

.user-popover .ant-popover-content .ant-popover-inner {
	background: linear-gradient(#f0f2f5 70px, white 0%);
	// background-color: blue;

	// f0f2f5
}

.flex-center {
	display: flex;
	justify-content: center;
}

.proovia-card {
	// height: 100%;
	border: none;
	border-top: 3px solid $main_color;
	box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
	transition: box-shadow 0.3s;

	.card-content {
		margin: 0 20px;
		color: gray;
		text-align: center;
		transition: color 1s;
	}

	&.proovia-badge {
		height: 100%;
		&:hover {
			.card-content {
				color: #303030;
			}
			box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
				0 5px 12px 4px rgb(0 0 0 / 9%);
		}
	}
}

.badge-container {
	display: grid;
	gap: 20px;
	max-width: 490px;
	grid-template-columns: repeat(auto-fit, 150px);
	margin: 40px auto 0 auto;
	justify-content: center;
	// align-items: center;
}

.ant-card-cover {
	margin: 25px;
}

.login-page {
	height: 100%;
	min-height: 466px;
	width: 100%;
	// text-align: center;
	position: absolute;
	justify-content: center;
	align-items: center;
	display: flex;
	background-color: #e9ecef;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.login-box {
	position: relative;
	border-radius: 16px;
}

.login-logo {
	padding: 10px;
	margin-bottom: 0;

	.img {
		width: 100%;
		height: 100%;
	}
}

.content-page {
	position: relative;
}

.ant-message {
	&-notice {
		text-align: right;
	}
}

// .preloader {
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   background-color: #f4f6f9;
//   height: 100vh;
//   width: 100%;

//   position: relative;

//   transition: height 200ms linear;
//   // left: 0;
//   // top: 0;
//   // z-index: 9999;
// }

// hide inptuNumber arrows
.input-number-no-arrows .ant-input-number-handler-wrap {
	display: none;
}

.content-loading {
	display: flex;
	position: absolute;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	z-index: 8888;
	background-color: rgba(255, 255, 255, 0.8);
	text-align: center;
}

.disabled-row {
	// background-color: #dcdcdc;
	opacity: 0.5;
	pointer-events: none;
}

a:disabled {
	color: gray;
	cursor: not-allowed;
}

.disabled a {
	color: #00000040;
	cursor: not-allowed;
}

.ant-timeline-item-content {
	p {
		margin-bottom: 5px;
	}
}

.ant-table-content {
	overflow: auto;
}

.blue-collapse {
	border: unset;
	border-radius: unset;

	.ant-collapse-item-disabled {
		border: 1px solid #d9d9d9;
		border-radius: 2px !important;
	}
	.ant-collapse-item:not(.ant-collapse-item-disabled) {
		border-radius: 2px;
		background-color: #41a5dd;
		border: 1px solid #41a5dd;
		.ant-collapse-header {
			color: #fff;
			font-weight: bold;
		}
	}
}

@import './buttons';
@import './siderMenu';
@import './footer';
@import './rowDraging';
@import './editableRow';
