.row-dragging {
	z-index: 1001;
	background: #fafafa;
	border: 1px solid #ccc;
	width: calc(90vw - 62px) !important;
	display: flex;
	align-items: center;
	td {
		padding: 16px;
		background: none;
		// border-bottom: 1px solid #ccc;
	}
	.drag-invisible {
		visibility: hidden;
	}
	.drag-visible {
		visibility: visible;
	}
}
