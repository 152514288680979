.editable-row .ant-form-item-explain {
	position: absolute;
	top: 100%;
	// font-size: 12px;
}

.ant-input-number {
	width: auto;
	&-handler-wrap {
		display: none;
	}
}
// .editable-cell {
//     position: relative;
// }

// .editable-cell-value-wrap {
//     padding: 5px 12px;
//     min-height: 32px;
//     cursor: pointer;
// }

// .editable-row:hover .editable-cell-value-wrap {
//     padding: 4px 11px;
//     border: 1px solid #d9d9d9;
//     border-radius: 2px;
// }

// [data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
//     border: 1px solid #434343;
// }
