.menu-over-button {
	margin-left: 250px;
	transition: 0s ease-in;
}

.ant-btn svg + span {
	margin-left: 8px;
}

.action-buttons {
	width: 160px;
}

.ant-btn-success {
	color: #fff;
	background: #28a745;
	border-color: #28a745;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
	> a {
		&:only-child {
			color: currentColor;
			&::after {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: transparent;
				content: '';
			}
		}
	}
	&:hover {
		color: #fff;
		background: #4cbe67;
		border-color: #4cbe67;
		> a {
			&:only-child {
				color: currentColor;
				&::after {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: transparent;
					content: '';
				}
			}
		}
	}
	&:focus {
		color: #fff;
		background: #4cbe67;
		border-color: #4cbe67;
		> a {
			&:only-child {
				color: currentColor;
				&::after {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: transparent;
					content: '';
				}
			}
		}
	}
	&:active {
		color: #fff;
		background: #1e7e34;
		border-color: #1e7e34;
		> a {
			&:only-child {
				color: currentColor;
				&::after {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: transparent;
					content: '';
				}
			}
		}
	}
}

.ant-btn-success.ant-popover-disabled-compatible-wrapper {
	background: unset;
}

.group-item {
	width: 100%;
}

.collapse-icon-button {
	width: 22px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	&-blue {
		color: #1890ff;
		&:hover {
			color: #40a9ff;
		}
		&:active {
			color: #096dd9;
		}
	}
	&-red {
		color: #ff4d4f;
		&:hover {
			color: #ff7875;
		}
		&:active {
			color: #d9363e;
		}
	}
	&:hover {
		transition: 0.4s;
	}
}
